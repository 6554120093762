import React from 'react';

function Signup() {
    return (
        <>
        <h1>Signup</h1>
        </>
    )
}

export default Signup;