//import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';

import { Home } from './components/home';
import { Game } from './components/game';
import { Login } from './components/login';
import { Signup } from './components/signup';
import { Dashboard } from './components/dashboard';
import { PasswordReset } from './components/passwordreset';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/play" element={<Game/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>
      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/passwordreset" element={<PasswordReset/>}/>
    </Routes>
  );
}

export default App;
