import { React, useEffect } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';

const socket = io();//.connect(`https://hcmmo.herokuapp.com:${process.env.PORT}`);

if (process.env.NODE_ENV === "production") {
    const socket = io.connect("https://hcmmo.herokuapp.com:3001");
}

import './Login.css'

function Login() {

    const signIn = () => {
        socket.emit("sign_in", { username: "hello world" })
    }

    useEffect(()=> {
        socket.on("received_sign_in", (data) =>{
            alert(data.username)
        })
    }, [socket])

    return (
        <section className="login">
            {/* <h1 className="login-headline">
                The World Awaits...
            </h1> */}
            <section className="login-modal">
                <section className="login-title">
                    The World Awaits...
                </section>
                    <form className="login-form">
                        <section className="login-form-item">
                            <label className="login-form-item-label">Email</label>
                            <input className="login-form-item-text"type="text" name="email"></input>
                        </section>
                        
                        <section className="login-form-item">
                            <label className="login-form-item-label">Password</label>
                            <input className="login-form-item-text"type="password" name="password"></input>
                        </section>

                        <Link className="login-submit" to="/dashboard">Log In</Link>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Link to="/passwordreset">Forgot your password?</Link>
                        <p>Don't have an account yet? Sign up instead.</p>
                    </form>
                    <button onClick={signIn} >Test</button>
            </section>
        </section>
    )
}

export default Login;