import React from 'react';
import { Link } from 'react-router-dom';

import { Navbar } from '../../common';

import './Header.css';


function Header() {
    return (
        <section className="header">
            <section className="header-logo">
                <Link className="header-link" to="/">HCMMO</Link>
            </section>
            <section className="header-navbar">
                <Navbar />
            </section>
        </section>
    )
}

export default Header;