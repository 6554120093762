import React from 'react';

import { Header } from '../common';

function Game() {
    return (
        <>
        <h1>Game</h1>
        <p></p>
        </>
    )
}

export default Game;