import React from 'react';
import { Link } from 'react-router-dom';

import { Header } from '../common';

import './Home.css';

function Home() {
    return (
        <>
        <Header />
        <section className="home-page">
            <h1 className="home-title">HARDCORE MMO</h1>

            <h2>===Roadmap===</h2>

            <Link to="/login">Login</Link>
            <br/>
            <Link to="/signup">Signup</Link>
            <br/>
            <Link to="/dashboard">Dashboard</Link>
            <br/>
            <Link to="/play">Play</Link>
            <br/>

            <h3>Responsive Navbar</h3>

            <p>I want the navbar to have a hamburger menu on screens that are too small. Then again, I may just not need very many links in the navbar. Clicking on the logo to go to the homepage or clicking the play button to go to the game should suffice, at least for now. So maybe this isn't a priority.</p>
            
            <br/>

            <h3>Sign up and Login</h3>

            <p>I'll need to hook up a server for this, which means adjusting the folder architecture somewhat.</p>
            <p>Upon clicking the play button, the user should be brought to the login page. This will simply feature a "modal" (it won't really be) with username and password textboxes, along with a sign in button, and below that a link to the sign up page.</p>
            <p>Upon signing in or signing up, the user should be brought to their account page, where they will eventually be able to see any of their characters. Selecting a Character will give you the option to enter the game as that character.</p>
            <p>This is assuming I allow players to have multiple characters. Honestly, with the way this game is going to work, I'm not sure if I want people to be able to have more than one Character. It comes down to balancing the way I want the game to be played (players should be VERY attached to their Characters) with the way I want to monetize it. Given that Characters will have only one life, I could potentially make a lot of money selling additional Characters.. but I don't want the game to be play to win. Maybe I'll just make players pay for every Character after their first and offer no options for free Characters. I had kind of liked the idea of being able to have kids, though. I definitely need to figure out a way for players that die to get back to their homes. Maybe you'll just be born wherever your previous Character died, but that could be dangerous. Perhaps players will have to build beds and/or nurseries or something to be able to spawn at, and any of your Characters beyond the first can spawn at any of the nurseries your previous characters have built that still remain.</p>

            <br/>

            <h3>Player Accounts and Characters</h3>

            <h3>Chat</h3>

            <p>Inventory & Equipment</p>

            <h3>Combat</h3>

            <h3>Decor</h3>

            <h3>Jewelry: Silver and Gold</h3>

            <p>Jewelry is used to store XP. Different Jewelry stores different Skills' XP depending on its gemstone. For example, emeralds might boost health, rubies attack, etc.</p>
            <p>Silver Jewelry will only bestow stored XP earned by Characters from the same family (player account) as the user, whereas Gold Jewelry bestows all of the XP ever stored in it to the user regardless of their family. </p>
            <p>When you are wearing jewelry and you gain XP of the given type, the jewelry item will earn a percentage of the XP you earn, ranging anywhere from .1-2% depending on how fine the jewelry is (in other words, depending on the level of the craftsman that made it).</p>

            <h3>Cooking and Baking</h3>

            <h3>Parties</h3>

            <h3>Guilds</h3>
            <p>Anyone can create a guild by creating a Guildmaster's Desk, which is essentially the Guild Workbench. At the Guildmaster's Desk players can craft Guild Banners, </p>

            <h3>Diseases, Vermin, and Contamination</h3>
            <p>Among the viruses in the game will be Lycanthropy, Vampirism, and the Zombie Virus.</p>
            <p>Becoming a werewolf will cause you to transform into a wolf during full moons (I need a day/night cycle AND a lunar cycle). When you are a wolf you will be stronger, but unable to use some abilities. Your claws and teeth will function basically like daggers. You will also be able to smell things - items that give off scents will produce colored smoke that will appear at the edge of your screen if they are too far away. You can tell what something is based on the color. For example, red smoke will indicate a person or animal.</p>

            <h3>You know what? I don't want to write this stuff here. I don't want it to be visible to players, I need to keep my updates more secret so that they're more exciting :)</h3>
            
        </section>
        </>
    )
}

export default Home;