import React from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

function Navbar() {
    return(
        <section className="navbar">
            <section className="navbar-item">
                <div className="navbar-playSection">
                    <Link className="navbar-playButton" to="/login">PLAY!</Link>
                </div>
            </section>
        </section>
    )
}

export default Navbar;