import React from 'react';

function PasswordReset() {
    return (
        <>
        <h1>Hope you keep better track of your next one!</h1>
        <p>In all seriousness - this just isn't set up yet. Apologies for the inconvenience. Please try again later.</p>
        </>
    )
}

export default PasswordReset;